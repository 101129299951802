import React from 'react'
import styled, { css } from 'styled-components'

type WrapperProps = {
  isFocus: boolean
  isFill: boolean
  isError: boolean
  children: React.ReactNode
}

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, isFocus, isFill, isError }) => css`
    width: 100%;

    height: 48px;

    border-bottom: 1px solid black;
    border-radius: 7px 7px 0 0;

    background: white;

    display: flex;

    ${isError &&
    css`
      border-color: red;
    `}

    ${(isFocus || isFill) &&
    css`
      border-color: green;
    `}
  `}
`

export const WrapperLeft = styled.div`
  display: flex;
  flex: 1;
  border-radius: 7px 7px 0 0;
`

export const Label = styled.label`
  display: flex;
  max-width: 300px;
  font-size: 14px;
`
export const Input = styled.input`
  ${() => css`
    flex: 1;

    border: none;
    background: transparent;

    color: green;
    font-weight: 400;
    font-size: 1.6rem;

    line-height: 48px;
    padding: 0 1.2rem;

    border-radius: 7px 7px 0 0;

    &::placeholder {
      color: blue;
      font-weight: 400;
      font-size: 1.6rem;
    }
  `}
`

export const ButtonPassword = styled.div<{ children: React.ReactNode, onClick: () => void }>`
  ${() => css`
    line-height: 48px;
    padding: 6px 1.2rem 0 6px;
    cursor: pointer;

    svg {
      color: green;
    }
  `}
`