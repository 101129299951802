import { ButtonHTMLAttributes, ReactNode } from 'react'
import { FiTrash } from 'react-icons/fi'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children?: ReactNode;
}

const Button = styled.button<ButtonProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  span {
    font-size: 12px;
    color: #e53e3e;
    font-weight: bold;
  }
`

export const RemoveBtnIcon = ({ ...rest }: ButtonProps) => {
  const { t } = useTranslation('global')

  return (
    <Button type="button" {...rest}>
      <FiTrash color="#E53E3E" size={24} />
      <span>{t('remove')}</span>
    </Button>
  )
}
