import React from 'react'
import styled, { css } from 'styled-components'

const dragActive = css`
  border-color: #d3de37;
`

const dragReject = css`
  border-color: #c54040;
`

type ContainerProps = {
  isDragActive?: boolean
  isDragReject?: boolean
  children?: React.ReactNode
}

export const Container = styled.div.attrs<ContainerProps>({
  className: 'dropzone'
})`
  background: #ffffff;

  border-radius: 4px;
  margin: 32px 0 12px;

  p {
    color: #4f4c4c;
    font-size: 14px;
    line-height: 24px;
  }

  ${(props: any) => !!props.isDragActive && dragActive};
  ${(props: any) => !!props.isDragReject && dragReject};

  @media (max-width: 480px) {
    padding: 20px;
  }
`

export const DropzoneStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1180px) {
    flex-direction: column;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2rem;
    padding: 0.5rem 1rem;
    margin: 0 0.5rem;
    background: #68d391;
    border-radius: 8px;
    color: #fff;
    transition: 0.2s ease-in;

    &:hover {
      filter: brightness(0.9);
    }
  }
`

export const ContentLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 8px;

  div {
    display: flex;
    justify-content: center;
    align-items: center;

    h4 {
      font-size: 14px;
      margin-left: 8px;

      span {
        color: #1890ff;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
  h4 {
    margin-top: 9px;
    margin-bottom: 4px;
    color: #4f4c4c;
    font-size: 16px;
  }
  p {
    color: #848080;
    font-size: 12px;
    text-align: center;
    width: 100%;
  }
  @media (max-width: 1180px) {
    p {
      margin-top: 24px;
    }
  }
`

const messageColors = {
  default: '#4F4C4C',
  error: '#c54040',
  sucess: '#D3DE37'
}

interface ContentDropzoneProps {
  type?: 'default' | 'error' | 'sucess'
  children: React.ReactNode
}

export const ContentDropzone = styled.div<ContentDropzoneProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 150px;
  color: ${(props) => messageColors[props.type || 'default']};
`

export const UploadedFile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid #f4f4f4;

  padding: 12px;

  h4 {
    font-size: 14px;
    margin-left: 8px;

    span {
      color: #1890ff;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  iframe {
    margin: 0 auto;
  }

  div.carregado {
    border-radius: 2px;
    width: 20px;
    height: 100%;
  }
`
