import { useEffect, useState } from "react";
import { 
  Heading, Text, Skeleton, useToast, Icon, 
  Flex, Badge, Box, Divider, HStack, VStack,
  Alert, AlertIcon
} from "@chakra-ui/react";
import { CheckCircleIcon, WarningIcon } from "@chakra-ui/icons";
import { apiAuth } from "../../services/apiAuth";

interface Plan {
  plan: string;
  unit_price: number;
  max_specialists: number;
  coupon_active: boolean;
  coupon: string | null;
  discount: number;
  final_price: number;
  current_specialists_count: number;
  remaining_specialists_slots: number;
  currency: string;
}

const CurrentPlanCard = () => {
  const [plan, setPlan] = useState<Plan | null>(null);
  const [loading, setLoading] = useState(true);
  const toast = useToast();

  useEffect(() => {
    const fetchPlan = async () => {
      try {
        const { data } = await apiAuth("clinics/payments/current-plan/");
        setPlan(data ?? null);
      } catch (error) {
        toast({
          title: "Erro ao carregar plano",
          description: "Não foi possível carregar as informações do plano.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchPlan();
  }, []);

  if (loading) {
    return (
      <Box p={6} borderRadius="lg" boxShadow="md" bg="white" w="full" maxW="900px">
        <HStack spacing={3} mb={4}>
          <Skeleton height="24px" width="24px" borderRadius="full" />
          <Skeleton height="24px" width="150px" />
        </HStack>
        <Divider mb={4} />
        <VStack align="stretch" spacing={3}>
          <Skeleton height="20px" width="80%" />
          <Skeleton height="20px" width="60%" />
          <Skeleton height="20px" width="70%" />
        </VStack>
      </Box>
    );
  }

  if (!plan) {
    return (
      <Box p={6} borderRadius="lg" boxShadow="md" bg="white" w="full" maxW="900px">
        <HStack spacing={3} mb={4}>
          <Icon as={WarningIcon} color="orange.500" boxSize={6} />
          <Heading size="md">Nenhum Plano Ativo</Heading>
        </HStack>
        <Divider mb={4} />
        <Alert status="warning" borderRadius="md">
          <AlertIcon />
          Nenhum plano foi definido para esta clínica. Entre em contato com o suporte para ativar um plano.
        </Alert>
      </Box>
    );
  }

  return (
    <Box p={6} borderRadius="lg" boxShadow="md" bg="white" w="full" maxW="900px">
      <HStack spacing={3} mb={4}>
        <Icon as={CheckCircleIcon} color="green.500" boxSize={6} />
        <Heading size="md">Plano Atual</Heading>
      </HStack>
      <Divider mb={4} />
      

      <Flex direction={{ base: "column", md: "row" }} justifyContent="space-between" mb={4}>
        <Box flex="1" mb={{ base: 4, md: 0 }}>
          <Heading size="md" mb={2}>
            Plano: <Badge colorScheme="blue" fontSize="lg">{plan.plan.toUpperCase()}</Badge>
          </Heading>
          <HStack spacing={2} mb={2}>
            <Text fontWeight="medium">Preço:</Text>
            <Text>{plan.unit_price.toFixed(2)} {plan.currency.toUpperCase()}</Text>
          </HStack>
          <HStack spacing={2}>
            <Text fontWeight="medium">Especialistas incluídos:</Text>
            <Text>{plan.max_specialists}</Text>
          </HStack>
        </Box>
        
        <Box flex="1" bg="gray.50" p={4} borderRadius="md">
          {plan.coupon_active && plan.coupon && (
            <HStack mb={2}>
              <Text fontWeight="medium">Cupom aplicado:</Text>
              <Badge colorScheme="green">{plan.coupon}</Badge>
              <Text>(-{plan.discount.toFixed(2)} {plan.currency.toUpperCase()})</Text>
            </HStack>
          )}
          <HStack mb={4} fontSize="xl">
            <Text fontWeight="bold">Preço Final:</Text>
            <Text fontWeight="bold" color="blue.600">{plan.final_price.toFixed(2)} {plan.currency.toUpperCase()}</Text>
          </HStack>
        </Box>
      </Flex>
      
      <Box p={3} bg="gray.50" borderRadius="md">
        <Flex justifyContent="space-between" alignItems="center">
          <HStack>
            <Text fontWeight="medium">Especialistas atuais:</Text>
            <Badge colorScheme={plan.current_specialists_count === plan.max_specialists ? "orange" : "blue"}>
              {plan.current_specialists_count} / {plan.max_specialists}
            </Badge>
          </HStack>
          <HStack>
            <Text fontWeight="medium">Vagas disponíveis:</Text>
            <Badge colorScheme={plan.remaining_specialists_slots === 0 ? "red" : "green"}>
              {plan.remaining_specialists_slots}
            </Badge>
          </HStack>
        </Flex>
      </Box>
    </Box>
  );
};

export default CurrentPlanCard;