import React, { useCallback, useState, useRef } from 'react'
import Webcam from 'react-webcam'
import format from 'date-fns/format'
import {
  Flex,
  Button,
  Icon,
  useBreakpointValue,
  useToast
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { AiOutlineVideoCamera } from 'react-icons/ai'
import { BsStopCircle } from 'react-icons/bs'
import ReactPlayer from 'react-player'
import { apiAuth } from '../../services/apiAuth'

type WebcamStreamCaptureProps = {
  cardId: string
  maxSize: number
  onUpdate: () => void
}

export const WebcamStreamCapture: React.FC<WebcamStreamCaptureProps> = ({
  cardId,
  maxSize,
  onUpdate
}) => {
  const [t] = useTranslation('webcamStreamComponent')
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  })
  const BASE_URL = `clinics/gallery-cards/${cardId}/videos/`
  const toast = useToast()
  const webcamRef = useRef<any>(null)
  const mediaRecorderRef = useRef<any>(null)
  const [capturing, setCapturing] = useState(false)
  const [recordedChunks, setRecordedChunks] = useState([])
  const [isVideo, setIsVideo] = useState(false)
  const [endCapturing, setEndCapturing] = useState(false)
  const [video, setVideo] = useState('')
  const [file, setFile] = useState<File | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const handleDataAvailable = useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data))
      }
    },
    [setRecordedChunks]
  )

  const handleStartCaptureClick = useCallback(() => {
    setCapturing(true)
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: 'video/webm'
    })
    mediaRecorderRef.current.addEventListener(
      'dataavailable',
      handleDataAvailable
    )
    mediaRecorderRef.current.start()
  }, [handleDataAvailable])

  const handleStopCaptureClick = useCallback(() => {
    mediaRecorderRef.current.stop()
    setCapturing(false)
    setEndCapturing(true)
  }, [mediaRecorderRef, setCapturing])

  const handlerViewAndGenerateVideoBlob = useCallback(() => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: 'video/webm'
      })
      const urlFile = URL.createObjectURL(blob)
      const fileCreate = new File(recordedChunks, 'clinikVideo')
      setVideo(urlFile)
      setFile(fileCreate)
      setIsVideo(true)
    }
  }, [recordedChunks])

  const handlerMakerNewVideo = () => {
    setIsVideo(false)
    setRecordedChunks([])
    setCapturing(false)
    setFile(null)
    setEndCapturing(false)
  }

  const renderCaptureButtons = (isCapturing: boolean, isEnd: boolean) => {
    if (isEnd) {
      return null
    }

    if (isCapturing) {
      return (
        <Button
          borderRadius="50%"
          w="60px"
          h="60px"
          zIndex="99"
          position="absolute"
          size="md"
          fontSize="md"
          bg="red.500"
          color="#fff"
          onClick={() => {
            handleStopCaptureClick()
          }}
          bottom="2"
          right="2"
          _hover={{ bg: 'white', color: 'red' }}
        >
          <Icon as={BsStopCircle} fontSize="24px" />
        </Button>
      )
    }

    return (
      <Button
        borderRadius="50%"
        w="60px"
        h="60px"
        zIndex="99"
        position="absolute"
        size="md"
        fontSize="md"
        bg="blue.500"
        color="#fff"
        _hover={{
          bg: 'blue.700'
        }}
        onClick={handleStartCaptureClick}
        bottom="2"
        right="2"
      >
        <Icon as={AiOutlineVideoCamera} fontSize="24px" />
      </Button>
    )
  }

  const handlerUploadVideo = async () => {
    setIsLoading(true)
    if (!file) {
      return
    }

    if (file.size > maxSize) {
      toast({
        duration: 3000,
        status: 'error',
        description: `${t('toast.errorSize')}`
      })

      return
    }

    const payload = new FormData()
    payload.append('file', file)
    payload.append('name', file.name)
    payload.append('date', format(new Date(), 'yyyy-MM-dd'))
    payload.append('description', file.name)

    try {
      const { data } = await apiAuth.post(BASE_URL, payload)

      if (data.id) {
        toast({
          duration: 3000,
          status: 'success',
          description: `${t('toast.success')}`
        })

        onUpdate()
        setIsLoading(false)
        setIsVideo(false)
        setFile(null)
        setRecordedChunks([])
      }
    } catch (error: any) {
      toast({
        duration: 3000,
        status: 'error',
        title: `${t('toast.error')}`,
        description: `${error.response?.data?.message}`
      })
    }
  }

  console.log(isVideo)

  return (
    <Flex flexDirection="column" w="100%" h="100%">
      {isVideo ? (
        <>
          <ReactPlayer url={video} controls height="100%" width="100%" />
          <Flex mt="2" w="100%" gap="2" justifyContent="flex-start">
            <Button
              size={isWideVersion ? 'md' : 'xs'}
              colorScheme="red"
              onClick={handlerMakerNewVideo}
            >
              Refazer
            </Button>
            <Button
              isLoading={isLoading}
              size={isWideVersion ? 'md' : 'xs'}
              colorScheme="blue"
              onClick={handlerUploadVideo}
            >
              Salvar
            </Button>
          </Flex>
        </>
      ) : (
        <>
          <Flex w="100%" h="100%" position="relative" bg="black">
            <Webcam
              width={isWideVersion ? 1280 : 'auto'}
              height={isWideVersion ? 720 : 'auto'}
              allowTransparency
              audio
              ref={webcamRef}
            />
            {renderCaptureButtons(capturing, endCapturing)}
          </Flex>
          {recordedChunks.length > 0 &&
            recordedChunks.length &&
            handlerViewAndGenerateVideoBlob()}
        </>
      )}
    </Flex>
  )
}
