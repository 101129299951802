/* eslint-disable camelcase */
import { useHistory } from 'react-router-dom'
import { useToast } from '@chakra-ui/react'
import { apiAuth } from '../../../services/apiAuth'
import { useDocument } from '../../../hooks/useDocument'

export type User = {
  id: number
  user: {
    id: number
    name: string
    email: string
    contact: string
    is_active: boolean
    is_specialist: boolean
    is_superuser: boolean
  }
  birth_date: string | null
  role: string
  image: object
  email: string | null
}

export type UsersData = User[]

export type PermissionsData = Permissions[]

const BASE_URL = 'users/'

export const useService = () => {
  // hooks
  const toast = useToast()
  const { setImgSrc } = useDocument()
  const router = useHistory()
  // states

  const handleUpdateUser = async (payload: object, id: string) => {
    try {
      const { data } = await apiAuth.put(`${BASE_URL}${id}/`, payload);

      if (data !== undefined) {
        toast({
          title: 'Atualização realizada com sucesso!',
          description: 'Estamos lhe redirecionando.',
          status: 'success',
          isClosable: true,
          duration: 3000,
          position: 'top'
        });

        setTimeout(() => {
          router.push('/settings/users');
          setImgSrc('');
        }, 2000);
      }
    } catch (err: any) {
      console.log(err);
      const errorMsg = err.response?.data?.user?.non_field_errors 
        ? err.response.data.user.non_field_errors.join(' ')
        : err.response?.data?.message || "Ocorreu um erro inesperado.";
      toast({
        title: 'Aconteceu um erro!',
        description: errorMsg,
        status: 'error',
        isClosable: true,
        duration: 3000,
        position: 'top'
      });
    }
  };

  const handleCreateNewUser = async (payload: object) => {
    try {
      const { data } = await apiAuth.post(BASE_URL, payload);

      if (data.id !== undefined) {
        toast({
          title: 'Cadastro realizado com sucesso!',
          description: 'Estamos lhe redirecionando.',
          status: 'success',
          isClosable: true,
          duration: 3000,
          position: 'top'
        });

        setTimeout(() => {
          router.push('/settings/users');
          setImgSrc('');
        }, 2000);
      }
    } catch (err: any) {
      console.log(err);
      const errorMsg = err.response?.data?.user?.non_field_errors 
        ? err.response.data.user.non_field_errors.join(' ')
        : err.response?.data?.message || "Ocorreu um erro inesperado.";
      toast({
        title: 'Aconteceu um erro!',
        description: errorMsg,
        status: 'error',
        isClosable: true,
        duration: 3000,
        position: 'top'
      });
    }
  };

  return {
    handleCreateNewUser,
    handleUpdateUser
  }
}
