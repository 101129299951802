/* eslint-disable camelcase */
import { useToast } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import { apiAuth } from '../../services/apiAuth'


export type Payment = {
  amount: number
  currency: string
  stripe_payment_id: string
  product: string
  created_at: string
  user_email: string
  clinic_id: number
  status: "pending" | "completed"
}


const BASE_URL = 'clinics/payments/'

const INTENT = "clinics/payments/create-payment-intent/"

export const useHistoryPayments = () => {
  // terceiros
  const router = useHistory()
  const toast = useToast()


  // função para criar
  async function createIntentPayment(callback?: string) {
    try {
      let amount = 0

      const { data } = await apiAuth.post(INTENT)

      if (data !== undefined) {
        toast({
          title: 'Iniciando Pagamento.',
          description: 'Estamos lhe redirecionando.',
          status: 'success',
          isClosable: true,
          duration: 3000,
          position: 'top'
        })

        if (data?.client_secret) {
          router.push("/payment-finish", { state: data?.client_secret })
        }
      }
    } catch (error: any) {
      toast({
        title: 'Error!',
        description: `${error.response?.data?.error}`,
        status: 'error',
        isClosable: true,
        duration: 3000,
        position: 'top'
      })
    }
  }

  async function getPaymentsHistory(currentPage: number) {
    try {
      const { data } = await apiAuth.get<{ count: number, results: Array<Payment> }>("clinics/payments/", {params: { page: currentPage }})

      return {
        totalCountOfPage: data?.count,
        data: data?.results
      }
    } catch (error) {
      return {
        totalCountOfPage: 0,
        data: []
      }
    }
  }


  // export
  return {
    createIntentPayment,
    getPaymentsHistory
  }
}
