/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable radix */
import { useCallback, useState } from 'react'
import Dropzone from 'react-dropzone'
import { uniqueId } from 'lodash'
import { useTranslation } from 'react-i18next'
import filesize from 'filesize'
import { RiUploadFill } from 'react-icons/ri'
import {
  Container,
  DropzoneStyle,
  ContentLeft,
  ContentDropzone
} from './styles'
import { useDocument } from '../../../../hooks/useDocument'

interface Images {
  file: {
    path: string
  }
  id: number
  name: string
  readbleSize: string
  preview: string
  progress: number
  uploaded: boolean
  error: boolean
  url: string | null
}

type ImagesData = Array<Images>

const DropZone = () => {
  const { t } = useTranslation('global')
  const { setImgSrc } = useDocument()
  const [imgUp, setImgUp] = useState<ImagesData>([])
  const [loading, setLoading] = useState(false)

  function getBase64(file: any) {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      if (reader.result !== undefined) {
        const base64Img: any = reader.result
        setImgSrc(base64Img)
      }
    }
    reader.onerror = (error) => {
      console.log('Error: ', error)
    }
  }

  const handleUpload = async (files: any) => {
    setLoading(true)
    const propsUploaded = await files.map((file: any) => ({
      file,
      id: uniqueId(),
      name: file.name,
      readbleSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: null
    }))

    setImgUp(propsUploaded)
    setLoading(false)
  }

  const renderDragMessage = useCallback((isDragActive, isDragReject) => {
    if (!isDragActive) {
      return (

          <ContentLeft>
            <div>
              <RiUploadFill size={48} color="#e6e6e6" />
              <h4>{t('dropzoneLabel')}</h4>
            </div>
          </ContentLeft>
      )
    }

    if (isDragReject) {
      return (
        <ContentDropzone type="error">
          <h1>Arquivo não suportado</h1>
        </ContentDropzone>
      )
    }

    return (
      <ContentDropzone type="sucess">
        <h1>Solte os arquivos aqui</h1>
      </ContentDropzone>
    )
  }, [])

  return (
      <Dropzone
        accept="image/*"
        onDropAccepted={(files) => handleUpload(files)}
      >
        {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
          <Container {...getRootProps()}>
            <DropzoneStyle>
              {' '}
              {/*  todas as propriedades de upload */}
              <input {...getInputProps()} />
              <>
                {loading ? (
                  <ContentLeft>
                    <p>Carregando...</p>
                  </ContentLeft>
                ) : (
                  <>
                    {imgUp.length > 0 ? (
                      <div style={{ width: `100%`, height: `100%` }}>
                        <img src={imgUp[0]?.preview} alt="flag" />
                      </div>
                    ) : (
                      <>{renderDragMessage(isDragActive, isDragReject)}</>
                    )}
                  </>
                )}
              </>
            </DropzoneStyle>
          </Container>
        )}
      </Dropzone>
  )
}

export default DropZone
