import React from 'react'
import styled, { css } from 'styled-components'

interface ContainerProps {
  isFocused?: boolean
  width?: number
  height?: number
  medium: boolean
  children?: React.ReactNode
}

export const Container = styled.div<ContainerProps>`
  ${({ medium, isFocused }) => css`
    div.react-select__control {
      width: 100% !important;
      height: ${medium ? '2.5rem' : '3rem'};
      border-width: 1px;
      border-radius: none !important;
    }

    .react-select__control--is-focused {
      border-width: 1px !important;
    }

    .react-select__value-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    ${isFocused &&
    css`
      border-color: #03719c;
    `}
  `}
`
export const customStyles = {
  control: () => ({
    width: '100%',
    height: '3rem',
    borderRadius: 'none',
    borderWidth: 1,
    display: 'flex',
    borderCoor: '#D1D2DC'
  })
}
