/* eslint-disable prettier/prettier */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Skeleton,
  Button
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'

import { useTranslation } from 'react-i18next'
import {} from '../../contexts/ModalContext'
import { NotFound } from '../../components/molecules/NotFound'
import { Pagination } from '../../components/PaginationNew'
import { useHistoryPayments } from './service'
import { LayoutBlank } from '../../layouts/blank'
import { formatBrOrDollarDefault } from '../../utils/fns/formatBrOrDollarDefault'
import { remaskCaractersAll } from '../../utils/fns/removeCaracters'
import CurrentPlanCard from '../../components/CurrentPlanCard'

// debounce
let timer: any = null

export function Payments() {
  const [t] = useTranslation('pagePayments')
  const { createIntentPayment, getPaymentsHistory  } = useHistoryPayments()
  const [currentPage, setCurrentPage] = useState(1)
  const skeletonRepeat = [1, 2, 3, 4, 5, 6, 7]

  const {
    data: getPaymentsHistoryData,
    isFetching,
    refetch,
    isLoading
  } = useQuery(
    [
      'historyPayments',
      currentPage,
    ],
    () =>
      getPaymentsHistory(
        currentPage
      )
  )

  useEffect(() => {
    refetch()
  }, [])


  return (
    <LayoutBlank
      title={t('title')}
      refetch={refetch}
      isFetching={isFetching}
      placeholder={t('search')}
      spiCode="ABOUT_CLINIC"
      noRenderIsActive
      isSearched={false}
    >
      <Box>
        <Box alignItems="self-start" justifyContent="start" m="2" mb="4">
          <Button color="white" background="blue.300" onClick={() => { createIntentPayment() }}>{t("newPayment")}</Button>
        </Box>
        <CurrentPlanCard />
        <Table variant="striped" colorScheme="blue" size="sm" mt="8">
          <Thead>
            <Tr>
              <Th>{t('t1')}</Th>
              <Th>{t('t2')}</Th>
              <Th>{t('t3')}</Th>
              <Th>{t('t4')}</Th>
              <Th>{t('t5')}</Th>
              <Th>{t('t6')}</Th>
              <Th>{t('t7')}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {isLoading ? (
              <>
                {skeletonRepeat.map((skeleton) => (
                  <Tr key={skeleton}>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                  </Tr>
                ))}
              </>
            ) : (
              <>
                {getPaymentsHistoryData?.data?.map((payment) => {
                  return (
                    <Tr key={payment.stripe_payment_id}>
                      <Td>{payment.stripe_payment_id}</Td>
                      <Td>{formatBrOrDollarDefault(remaskCaractersAll(String(payment.amount)))}</Td>
                      <Td>{payment.currency}</Td>
                      <Td>{payment.product}</Td>
                      <Td>{payment.user_email}</Td>
                      <Td>{payment.created_at}</Td>
                      <Td>{payment.status === "pending" ? `${t("status.pending")}` : (t("status.completed"))}</Td>
                    </Tr>
                  )
                })}
              </>
            )}
          </Tbody>
        </Table>
        {getPaymentsHistoryData?.totalCountOfPage === 0 && !isLoading && <NotFound />}
      </Box>
      {!(getPaymentsHistoryData?.totalCountOfPage === 0 && !isLoading) && (
        <Pagination
          totalCountOfRegisters={getPaymentsHistoryData?.totalCountOfPage ?? 0}
          currentPage={currentPage}
          onPageChange={setCurrentPage}
        />
      )}
    </LayoutBlank>
  )
}
