import React, { useCallback } from 'react'
import Dropzone from 'react-dropzone'
import { Text, Flex } from '@chakra-ui/react'
import { RiUploadFill } from 'react-icons/ri'
import { useTranslation } from 'react-i18next'
import { useDocument } from '../../hooks/useDocument'

import * as C from './style'

interface UploadProps {
  accept?: string
  maxInfoSize?: number
  maxSize?: number
  isDisabled?: boolean
}

export const Upload: React.FC<UploadProps> = ({
  accept,
  maxInfoSize = 1,
  maxSize = 1048576,
  isDisabled = false
}) => {
  const [t] = useTranslation('global')
  const { handlerUpload, onDropRejected } = useDocument()

  const renderDragMessage = useCallback(
    (isDragActive: boolean, isDragReject: boolean) => {
      if (!isDragActive) {
        return (
            <C.ContentDropzone>
              <RiUploadFill size={16} color="#e6e6e6" />
              <p>{t('dropzoneLabel')}</p>
            </C.ContentDropzone>
        )
      }

      if (isDragReject) {
        return (
          <C.ContentDropzone type="error">
            <p>{t('dropzoneLabelError')}</p>
          </C.ContentDropzone>
        )
      }

      return (
        <C.ContentDropzone type="success">
          <p>{t('dropzoneLabelSuccess')}</p>
        </C.ContentDropzone>
      )
    },
    [t]
  )

  return (
    <Dropzone
      accept={accept ?? 'image/*'}
      maxSize={maxSize}
      disabled={isDisabled}
      onDropRejected={onDropRejected}
      onDropAccepted={handlerUpload}
    >
      {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
        <>
          <C.DropContainer
            {...getRootProps()}
            isDragActive={isDragActive}
            isDragReject={isDragReject}
          >
            <input {...getInputProps()} />
            {renderDragMessage(isDragActive, isDragReject)}
          </C.DropContainer>
          <Flex
            mt="2"
            w="100%"
            px="4"
            alignItems="center"
            justifyContent="flex-end"
          >
            <Text fontSize="12px" color="#999">
              {`${t('dropzoneLabelSize')} ${maxInfoSize} mb`}
            </Text>
          </Flex>
        </>
      )}
    </Dropzone>
  )
}
