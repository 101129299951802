import React from 'react'
import styled, { css } from 'styled-components'

const dragActive = css`
  border-color: #d3de37;
`

const dragReject = css`
  border-color: #c54040;
`

type ContainerProps = {
  isDragActive?: boolean
  isDragReject?: boolean
  children: React.ReactNode
}

export const Container = styled.div.attrs<ContainerProps>({
  className: 'dropzone'
})`
  width: 160px;
  height: 190px;
  position: relative;
  border: 2px dashed #ccc8c8;
  box-shadow: 0px 4px 6px #00000014;
  background: #ffffff;
  padding: 3rem 1rem;
  border-radius: 8px;
  margin: 16px 0 12px;

  p {
    color: #4f4c4c;
    font-size: 14px;
    line-height: 24px;
  }

  ${(props: any) => !!props.isDragActive && dragActive};
  ${(props: any) => !!props.isDragReject && dragReject};

  @media (max-width: 480px) {
    padding: 20px;
  }
`

export const DropzoneStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1180px) {
    flex-direction: column;
  }
  img {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    border-radius: 8px;
  }
`

export const ContentLeft = styled.div`
  width: 200px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h4 {
      text-align: center;
      width: 100%;
    }
  }
  h4 {
    margin-top: 9px;
    margin-bottom: 4px;
    color: #4f4c4c;
    font-size: 16px;
  }
  p {
    color: #848080;
    font-size: 12px;
    text-align: center;
    width: 100%;
  }
  @media (max-width: 1180px) {
    p {
      margin-top: 24px;
    }
  }
`

const messageColors = {
  default: '#4F4C4C',
  error: '#c54040',
  sucess: '#D3DE37'
}

interface ContentDropzoneProps {
  type?: 'default' | 'error' | 'sucess'
  children: React.ReactNode
}

export const ContentDropzone = styled.div<ContentDropzoneProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 150px;
  color: ${(props) => messageColors[props.type ?? 'default']};
`
