import React, { FormEvent, useEffect, useState } from "react";
import {
  Box,
  Button,
  Heading,
  Text,
  useToast,
  Flex,
  Stack,
  Center,
  Spinner,
} from "@chakra-ui/react";
import {
  Elements,
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { loadStripe, StripeElementsOptions } from "@stripe/stripe-js";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";

interface LocationState {
  state: string;
}

const STRIPE_PUB_KEY = process.env.REACT_APP_STRIPE_PUB_KEY ?? "";
const stripePromise = loadStripe(STRIPE_PUB_KEY);

const PaymentForm = () => { 
  const navigate = useHistory();
  const [t] = useTranslation("global");
  const stripe = useStripe();
  const elements = useElements();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    if (!stripe || !elements) return;

    setIsLoading(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/payments`,
      },
      redirect: 'if_required',
    });

    if (error) {
      toast({
        title: 'Erro no pagamento',
        description: error.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    } else if (paymentIntent && paymentIntent.status === 'succeeded') {
      toast({
        title: 'Pagamento realizado com sucesso',
        description: 'Obrigado pelo seu pagamento!',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });

      setTimeout(() => {
        navigate.push('/payments');
      }, 2000)
    }

    setIsLoading(false);
  };

  return (
    <Box
      maxW="500px"
      w="full"
      bg="white"
      boxShadow="lg"
      rounded="lg"
      p={8}
    >
      <Stack spacing={4} textAlign="center">
        <Heading as="h2" size="lg">
          {t("paymentHeader")}
        </Heading>
        <Text color="gray.600">
          {t("paymentDetails")}
        </Text>
      </Stack>
      <Box as="form" mt={8} onSubmit={handleSubmit}>
        <PaymentElement options={{ layout: "tabs" }} />
        <Button
          mt={6}
          w="full"
          colorScheme="blue"
          type="submit"
          isLoading={isLoading}
          disabled={!stripe}
        >
          {t("payment")}
        </Button>
      </Box>
    </Box>
  );
};

const PaymentPage = () => {
  const [options, setOptions] = useState<StripeElementsOptions | undefined>(
    undefined
  );
  const location = useLocation<LocationState>();
  const { state } = location.state || {};

  useEffect(() => {
    if (state) {
      setOptions({
        clientSecret: state,
        appearance: {
          theme: "stripe",
        },
      });
    }
  }, [state]);

  return options ? (
    <Flex minH="100vh" align="center" justify="center" bg="gray.50" p={4}>
      <Elements stripe={stripePromise} options={options}>
        <PaymentForm />
      </Elements>
    </Flex>
  ) : (
    <Flex minH="100vh" align="center" justify="center" bg="gray.50" p={4}>
      <Center>
        <Spinner color="blue" />
      </Center>
    </Flex>
  );
};

export default PaymentPage;
